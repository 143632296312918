<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <b-nav-item
        v-if=" menuService == 'e-commerce'"
        class="d-none d-lg-block"
        @click="demo()"
      >
        <feather-icon
          size="21"
          :icon="'SmartphoneIcon'"
        />
      </b-nav-item>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{
                getUserData != null
                  ? `${getUserData.firstName} ${getUserData.lastName}`
                  : $t("Please Login")
              }}
            </p>
            <span class="user-status">{{ authType }}</span>
          </div>
          <b-avatar
            v-if="getUserData"
            size="40"
            variant="light-primary"
            badge
            :src="getUserData.image"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="menuService==='franchise'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
          />
          <span>{{ $t("Franchise") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="menuService==='company'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="HomeIcon"
            class="mr-50"
          />
          <span>{{ $t("Company") }}</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t("Profile") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>{{ $t("Inbox") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>{{ $t("Task") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="chatPage"
        >
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>{{ $t("Chat") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          v-if="getUserData != null"
          link-class="d-flex align-items-center"
          @click="logoutToType"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="getUserData == null"
          link-class="d-flex align-items-center"
          @click="login"
        >
          <feather-icon
            size="16"
            icon="LogInIcon"
            class="mr-50"
          />
          <span>{{ $t("Login") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="menuService == 'franchise' || menuService == 'company' | menuService == 'e-commerce'"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{
                navbarTitle
              }}
            </p>
            <span class="user-status">{{ $t(menuService.toUpperCase()) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="menuService == 'franchise' ? getFranchiseData.logo : menuService == 'company' ? getCompanyData.logo : menuService == 'e-commerce' ? getApplicationData.logo : ''"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="getUserData != null"
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BNavItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, rootAbility, franchiseAbility, companyAbility, eCommerceAbility,
} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/layouts/components/app-navbar/Locale.vue'
import NotificationDropdown from '@/layouts/components/app-navbar/NotificationDropdown.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import axios from '@axios'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BNavItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,

    // Locale
    Locale,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    menuService() {
      return store.state.appConfig.menuService
    },
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    authType() {
      if (this.getUserData !== null) {
        if (this.getUserData.userType.toUpperCase() === this.menuService.toUpperCase()) {
          return this.$t(this.menuService.toUpperCase())
        }
        return `${this.$t(this.menuService.toUpperCase())} (${this.$t(this.getUserData.userType.toUpperCase())})`
      }
      return `${this.$t(this.menuService.toUpperCase())}`
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
    getFranchiseData() {
      return localStorage.getItem('franchiseData') != null
        ? JSON.parse(localStorage.getItem('franchiseData'))
        : null
    },
    getCompanyData() {
      return localStorage.getItem('companyData') != null
        ? JSON.parse(localStorage.getItem('companyData'))
        : null
    },
    getApplicationData() {
      return store.state.appConfig.applicationData
    },
    urlWeb() {
      if (process.env.NODE_ENV === 'production') {
        return `https://ui-storeapp.netlify.app/?tokenApplication=${this.getApplicationData.token}&applicationId=${this.getApplicationData._id}&companyId=${this.getApplicationData.companyId._id}&franchiseId=${this.getApplicationData.franchiseId._id}`
      }
      return `http://localhost:3403/?tokenApplication=${this.getApplicationData.token}&applicationId=${this.getApplicationData._id}&companyId=${this.getApplicationData.companyId._id}&franchiseId=${this.getApplicationData.franchiseId._id}`
    },
  },
  methods: {
    demo() {
      window.open(this.urlWeb, 'popupWindow', 'width=520,height=900,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes')
    },
    getAbility(item, path) {
      axios.get(`api/web/getAbility/get?type=${item}`)
        .then(response => {
        //
          this.$ability.update(response.data.data)
          localStorage.setItem('ability', JSON.stringify(response.data.data))
          this.$router.replace({ name: path })
        })
      // eslint-disable-next-line no-unused-vars
        .catch(error => {
        // console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    chatPage() {
      if (this.menuService === 'e-commerce') {
        this.$router.push({ name: 'e-commerce-chat' })
      } else if (this.menuService === 'company') {
        this.$router.push({ name: 'company-chat' })
      } else if (this.menuService === 'franchise') {
        this.$router.push({ name: 'franchise-chat' })
      } else if (this.menuService === 'root') {
        this.$router.push({ name: 'root-chat' })
      }
    },
    logoutToType() {
      const role = localStorage.getItem('role') ? localStorage.getItem('role') : ''
      if (this.getUserData.userType === 'root') {
        if (this.menuService === 'root') {
          const commonKeys = [
            useJwt.jwtConfig.storageTokenKeyName,
            useJwt.jwtConfig.storageRefreshTokenKeyName,
            'userData', 'role', 'ability',
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData', 'franchiseId', 'franchiseData',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-login' })
        } else {
          this.$ability.update(rootAbility())
          localStorage.setItem('ability', JSON.stringify(rootAbility()))

          this.$router.replace({ name: 'root-dashboard' })
        }
      } else if (this.getUserData.userType === 'franchise') {
        if (this.menuService !== 'franchise') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData',
          ]
          this.clearLocalStorage([...commonKeys])
          if (role === 'admin') {
            this.$ability.update(franchiseAbility())
            localStorage.setItem('ability', JSON.stringify(franchiseAbility()))
            this.$router.replace({ name: 'franchise-dashboard' })
          } else {
            this.getAbility('franchise', 'franchise-dashboard')
          }
        } else {
          const commonKeys = [
            useJwt.jwtConfig.storageTokenKeyName,
            useJwt.jwtConfig.storageRefreshTokenKeyName,
            'userData', 'role', 'ability',
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData', 'franchiseId', 'franchiseData',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-login' })
        }
      } else if (this.getUserData.userType === 'company') {
        if (this.menuService !== 'company') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
          ]
          this.clearLocalStorage([...commonKeys])
          if (role === 'admin') {
            this.$ability.update(companyAbility())
            localStorage.setItem('ability', JSON.stringify(companyAbility()))
            this.$router.replace({ name: 'company-dashboard' })
          } else {
            this.getAbility('company', 'company-dashboard')
          }
        } else {
          const commonKeys = [
            useJwt.jwtConfig.storageTokenKeyName,
            useJwt.jwtConfig.storageRefreshTokenKeyName,
            'userData', 'role', 'ability',
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData', 'franchiseId', 'franchiseData',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-login' })
        }
      } else if (this.getUserData.userType === 'staff') {
        const commonKeys = [
          useJwt.jwtConfig.storageTokenKeyName,
          useJwt.jwtConfig.storageRefreshTokenKeyName,
          'userData', 'role', 'ability',
          'applicationId', 'applicationData', 'tokenApplication',
          'companyId', 'companyData', 'franchiseId', 'franchiseData',
        ]
        this.clearLocalStorage([...commonKeys])
        this.$ability.update(initialAbility)
        const applicationUrl = localStorage.getItem('applicationUrl') ? localStorage.getItem('applicationUrl') : ''
        console.log(applicationUrl)
        if (applicationUrl !== '') {
          this.$router.push({ path: `/${applicationUrl}/login` })
        } else {
          this.$router.replace({ name: 'auth-login' })
        }
      }
    },
    logout() {
      const role = localStorage.getItem('role') ? localStorage.getItem('role') : ''
      if (this.menuService === 'e-commerce') {
        if (this.getUserData.userType === 'root') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(companyAbility())
          localStorage.setItem('ability', JSON.stringify(companyAbility()))

          this.$router.replace({ name: 'company-application' })
        } else if (this.getUserData.userType === 'franchise') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(companyAbility())
          localStorage.setItem('ability', JSON.stringify(companyAbility()))
          this.$router.replace({ name: 'company-application' })
        } else if (this.getUserData.userType === 'company') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
          ]
          this.clearLocalStorage([...commonKeys])
          if (role === 'admin') {
            this.$ability.update(companyAbility())
            localStorage.setItem('ability', JSON.stringify(companyAbility()))
            this.$router.replace({ name: 'company-application' })
          } else {
            this.getAbility('company', 'company-application')
          }
        } else if (this.getUserData.userType === 'staff') {
          const commonKeys = [
            useJwt.jwtConfig.storageTokenKeyName,
            useJwt.jwtConfig.storageRefreshTokenKeyName,
            'userData', 'role', 'ability',
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData', 'franchiseId', 'franchiseData',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(initialAbility)
          const applicationUrl = localStorage.getItem('applicationUrl') ? localStorage.getItem('applicationUrl') : ''
          console.log(applicationUrl)
          if (applicationUrl !== '') {
            this.$router.push({ path: `/${applicationUrl}/login` })
          } else {
            this.$router.replace({ name: 'auth-login' })
          }
        }
      } else if (this.menuService === 'company') {
        if (this.getUserData.userType === 'root') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(franchiseAbility())
          localStorage.setItem('ability', JSON.stringify(franchiseAbility()))

          this.$router.replace({ name: 'franchise-company' })
        } else if (this.getUserData.userType === 'franchise') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData',
          ]
          this.clearLocalStorage([...commonKeys])
          if (role === 'admin') {
            this.$ability.update(franchiseAbility())
            localStorage.setItem('ability', JSON.stringify(franchiseAbility()))
            this.$router.replace({ name: 'franchise-company' })
          } else {
            this.getAbility('franchise', 'franchise-company')
          }
        } else if (this.getUserData.userType === 'company') {
          const commonKeys = [
            'role', 'ability',
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData', 'franchiseId', 'franchiseData',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'select-company' })
        }
      } else if (this.menuService === 'franchise') {
        if (this.getUserData.userType === 'root') {
          const commonKeys = [
            'applicationId', 'applicationData', 'tokenApplication',
            'companyId', 'companyData', 'franchiseId', 'franchiseData',
          ]
          this.clearLocalStorage([...commonKeys])
          this.$ability.update(rootAbility())
          localStorage.setItem('ability', JSON.stringify(rootAbility()))

          this.$router.replace({ name: 'root-franchise' })
        } else if (this.getUserData.userType === 'franchise') {
          const commonKeys = [
            'role', 'ability', 'franchiseId', 'franchiseData',
          ]
          this.clearLocalStorage([...commonKeys])

          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'select-franchise' })
        }
      } else if (this.menuService === 'root') {
        const commonKeys = [
          useJwt.jwtConfig.storageTokenKeyName,
          useJwt.jwtConfig.storageRefreshTokenKeyName,
          'userData', 'role', 'ability',
          'applicationId', 'applicationData', 'tokenApplication',
          'companyId', 'companyData', 'franchiseId', 'franchiseData',
        ]
        this.clearLocalStorage([...commonKeys])

        this.$ability.update(initialAbility)
        this.$router.replace({ name: 'auth-login' })
        store.commit('appConfig/UPDATE_MENU_SERVICE', '')
      }
    },
    login() {
      const commonKeys = [
        useJwt.jwtConfig.storageTokenKeyName,
        useJwt.jwtConfig.storageRefreshTokenKeyName,
        'userData', 'role', 'ability',
        'applicationId', 'applicationData', 'tokenApplication',
        'companyId', 'companyData', 'franchiseId', 'franchiseData',
      ]
      this.clearLocalStorage([...commonKeys])

      this.$ability.update(initialAbility)

      this.$router.replace({ name: 'auth-login' })
      store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    },
    clearLocalStorage(keys) {
      keys.forEach(key => localStorage.removeItem(key))
    },
  },
}
</script>
